@import "~bootstrap/scss/functions";
@import "../../../assets/styles/vars";
@import "~bootstrap/scss/mixins";

html{
  background: $gradient-bg;
  min-height: 100vh;
}

.home {
  background: $home-bg;
  color: $white;
  min-height: 100vh;
}

.modern{
  color: $white !important;
}

.project-list{
  background: $gradient-bg;
  min-height: 100vh;
}

.team-list{
  background: $gradient-bg;
  min-height: 100vh;
}

.hero {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  @include media-breakpoint-down(sm) {
    padding-top: 4rem;
  }

  > h1 {
    max-width: 14em;
    font-weight: $headings-font-weight;
    margin-left: auto;
    margin-right: auto;
  }
}

.fly-shape {
  position: absolute;
  top: 40vh;
  left: 10vw;
  width: 25vw;
  opacity: .06;

  &_right {
    width: 15vw;
    top: 25vw;
    left: auto;
    right: 5vw;
    transform: rotate(45deg);
    opacity: .04;
  }

  &_center {
    width: 10vw;
    top: 10vw;
    left: 50%;
    right: auto;
    opacity: .02;
  }

  > img {
    width: 100%;
    transform: rotate(-25deg);
  }
}

.motivation {
  position: relative;
  h2 {
    font-weight: 700;
    max-width: 1100px;
    line-height: .95;

    @include media-breakpoint-up(md) {
      font-size: 6rem;
      color: $black;
      opacity: .2;
    }

    @include media-breakpoint-up(lg) {
      font-size: 8rem;
      margin: 0 auto 5rem;
    }

    @include media-breakpoint-up(xl) {
      font-size: 11rem;
    }
  }
}

.who {
  min-height: 70vh;
  background: url(/assets/img/home/stefano-pollio-365695-unsplash.jpg) no-repeat center/cover fixed;
  color: $gray-900;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover,
  &:active {
    color: $gray-900;
    text-decoration: none;
  }

  h2 {
    font-size: 4rem;
  }
}


.slick-slide {
  opacity: .3;
}
.slick-center {
  opacity: 1;
}
