@import "fonts";
@import "libs";

@import "theme/index";

@import "media"; // Always at the end

body{
  color: #ffffff;
}

footer{
  color: $gray-900;
}

.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
  width: 100%;
}